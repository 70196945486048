<template>
    <div>
        <ValidationObserver ref="examOverlapControl">
            <b-row>
                <b-col cols="12" md="6" lg="4">
                    <ValidationProvider name="faculty_code" v-slot="{ errors }">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="filter.faculty_code" :validate-error="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <ValidationProvider name="semester_id" v-slot="{ errors }">
                        <b-form-group :label="$t('semester')">
                            <semesters-selectbox v-model="filter.semester_id">
                            </semesters-selectbox>
                            <b-form-invalid-feedback v-for="(err, index) in errors" :key="index"> {{ err }} </b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <ValidationProvider name="type" rules="" v-slot="{ errors }">
                        <b-form-group :label="$t('exam_type')">
                            <parameter-selectbox :validate-error="errors[0]" code="exam_schedules_types"
                                v-model="filter.type"></parameter-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group :label="$t('course')">
                        <ValidationProvider name="course_id" v-slot="{ errors }">
                            <course-auto-complete v-model="filter.course_id" />
                            <b-form-invalid-feedback v-for="(err, index) in errors" :key="index"> {{ err }} </b-form-invalid-feedback>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group :label="$t('teaching_staff')">
                        <ValidationProvider name="instructor_id" v-slot="{ errors }">
                            <staff-auto-complete v-model="filter.instructor_id" :validate-error="errors[0]" />
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group :label="$t('date')">
                        <b-form-input type="date" v-model="filter.date">
                        </b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group :label="$t('start_time')">
                        <b-form-input type="time" v-model="filter.start_time">
                        </b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group :label="$t('end_time')">
                        <b-form-input type="time" v-model="filter.end_time">
                        </b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <ValidationProvider name="type" rules="" v-slot="{ errors }">
                        <b-form-group :label="$t('exam_type')">
                            <parameter-selectbox :validate-error="errors[0]" code="exam_schedules_types"
                                v-model="filter.test"></parameter-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <!-- <b-row>
                <b-col cols="12" md="6" lg="4">
                    <ValidationProvider name="course_code1"  v-slot="{valid, errors}">
                        <b-form-group :label="`${$t('course_code')}-1`">
                            <CourseAutoComplete v-model="filter.course_code1"
                                        :state="errors[0] ? false : null" resultType="code"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <ValidationProvider name="course_code2"  v-slot="{valid, errors}">
                        <b-form-group :label="`${$t('course_code')}-2`">
                            <CourseAutoComplete v-model="filter.course_code2"
                                        :state="errors[0] ? false : null" resultType="code"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row> -->
        </ValidationObserver>
        <b-row>
            <b-col cols="6" sm="4" md="3" lg="2">
                <b-form-group class="mb-0">
                    <b-button variant="primary" block @click="getReport">
                        {{ $t('get_report') }}
                    </b-button>
                </b-form-group>
            </b-col>
            <b-col cols="6" sm="4" md="3" lg="2">
                <b-form-group class="mb-0">
                    <b-button variant="danger" block @click="clear">
                        {{ $t('clear') }}
                    </b-button>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import qs from 'qs'

import ExamScheduleServiceService from "@/services/ExamScheduleService";

export default {
    name: "examOverlapReport",
    data() {
        return {
            filter: {}
        }
    },
    methods: {
        async getReport() {
            const isValid = await this.$refs.examOverlapControl.validate();
            if (!isValid) {
                return
            }

            const config = {
                params: {
                    filter: this.filter
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            ExamScheduleServiceService.overlapReport(config)
                .then(response => {
                    this.$toast.success(this.$t('api.' + response.data.message));
                    this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                })
                .catch(err => this.showErrors(err))
        },
        clear() {
            this.filter = {}
        }
    }
}
</script>


<style lang="scss" scoped>
.form-group {
    .btn {
        line-height: 22px
    }
}
</style>