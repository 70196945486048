<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('reports_student')" :isFilter="false" />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('reports_student')" :isFilter="false" />
            </template>
            <h6>{{ $t('select_report_type') }}</h6>
            <div class="border rounded p-5 mb-3">
                <multiselect :clear-on-select="true" :close-on-select="true" :deselect-label="''"
                    :options="computeReportTypes" :placeholder="$t('select')" :select-label="''" :selected-label="''"
                    label="text" track-by="value" v-model="reportType" />
            </div>
            <div class="col-12" v-if="reportType">
                <h6>
                    <span v-if="reportType && reportType.text">
                        {{ reportType.text }}
                    </span>
                </h6>
                <div class="border rounded p-5">
                    <component v-if="reportType && reportType.value" :is="reportType.value" />
                </div>
            </div>
        </app-layout>
    </div>
</template>

<script>
// Template
import AppLayout from '@/layouts/AppLayout'
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
// Data
import ReportTypes from '../../data/StudentReportTypes'
// Pages
import MissingDocumentsReport from './missingDocumentsReport/Index'
import StudentNotesReport from './studentNotesReport/Index'
import FullScholarshipStudents from './fullScholarshipStudents'
import RegistrationNumbersByFaculty from './registrationNumbersByFaculty/Index'
import NumberOfStudentsByStatus from './numberOfStudentsByStatus/Index'
import YokRegistrationStatusReport from './yokRegistrationStatusReport/Index'
import examOverlapReport from './examOverlapReport'
// import graduationEligibilityReport from './graduationEligibilityReport/Index.vue'

export default {
    name: "StudentReportPage",
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        MissingDocumentsReport,
        StudentNotesReport,
        FullScholarshipStudents,
        RegistrationNumbersByFaculty,
        NumberOfStudentsByStatus,
        YokRegistrationStatusReport,
        examOverlapReport,
        graduationEligibilityReport: () => import('./graduationEligibilityReport/index.vue')
        // graduationEligibilityReport,
    },
    metaInfo() {
        return {
            title: this.$t('reports_student')
        }
    },
    data() {
        return {
            reportType: null
        }
    },
    computed: {
        computeReportTypes() {
            return ReportTypes().filter((item) => {
                return this.checkPermission(item.permission)
            })
        }
    }
}
</script>
