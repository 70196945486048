<template>
    <div>
        <b-row>
            <b-col cols="12" md="7">
                <b-form-group :label="$t('faculty')">
                    <faculty-selectbox v-model="filter.faculty_code"/>
                </b-form-group>
                <b-form-group :label="$t('department')">
                    <department-selectbox v-model="filter.department_code"
                                          :faculty_code="filter.faculty_code"
                    />
                </b-form-group>
                <b-form-group :label="$t('program')">
                    <program-selectbox v-model="filter.program_code"
                                       :faculty_code="filter.faculty_code"
                                       :department_code="filter.department_code"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="12" md="3">
                <b-form-group :label="$t('student_number')">
                    <b-form-input v-model="filter.student_number"
                                  type="number"/>
                </b-form-group>
                <b-form-group :label="$t('name')">
                    <b-form-input type="text" v-model="filter.name"/>
                </b-form-group>
                <b-form-group :label="$t('surname')">
                    <b-form-input type="text" v-model="filter.surname"/>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="2">
                <b-form-group class="mb-0">
                    <label class="d-none d-md-block">&nbsp;</label>
                    <b-button variant="primary" block @click="getReport">
                        {{ $t('get_report') }}
                    </b-button>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>
<script>
    // Components
    import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox'
    import DepartmentSelectbox from '@/components/interactive-fields/DepartmentSelectbox'
    import ProgramSelectbox from '@/components/interactive-fields/ProgramSelectbox'

    // Services
    import StudentProgramService from '@/services/StudentProgramService'

    // Other
    import qs from 'qs'

    export default {
        components: {
            FacultySelectbox,
            DepartmentSelectbox,
            ProgramSelectbox
        },
        data() {
            return {
                selected: null,
                filter: {
                    faculty_code: null,
                    department_code: null,
                    program_code: null,
                    student_number: null,
                    name: null,
                    surname: null
                }
            }
        },
        methods: {
            getReport() {
                const config = {
                    params: {
                        filter: {
                            ...this.filter
                        },
                        page: -1
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                }

                return StudentProgramService.missingDocumentsReport(config)
                                            .then((response) => {
                                                this._downloadFile(response, this.$t('file_name_missing_documents_report') + '.xlsx')
                                            })
            }
        }
    }
</script>
