import Api from '@/services/Index';

const registrationNumbersByFaculty = async (config) => {
    return Api.get('/student/reports/registration-numbers-by-faculty', config);
}

const numberOfStudentByStatus = async (config) => {
    return Api.get('/student/reports/number-of-students-by-status', config);
}

export default {
    registrationNumbersByFaculty,
    numberOfStudentByStatus
}
