// Plugins
import i18n from "@/plugins/i18n";

export default function () {
    let reportTypes = [];

    // Missing Documents
    // reportTypes.push({
    //     value: "missing-documents-report",
    //     text: i18n.t("missing_documents_report"),
    //     permission: "studentprogram_missingdocumentexcelexport",
    // });

    // Notes Report
    reportTypes.push({
        value: "student-notes-report",
        text: i18n.t("student_notes_report"),
        permission: "student_studentnotesreport",
    });

    // Full Scholarship
    // reportTypes.push({
    //     value: "full-scholarship-students",
    //     text: i18n.t("full_scholarship_students_enroll"),
    //     permission: "student_studentnotesreport",
    // });

    reportTypes.push({
        value: "registration-numbers-by-faculty",
        text: i18n.t("registration_numbers_by_faculty_report"),
        permission: "studentreport_registrationnumbersbyfaculty",
    });

    reportTypes.push({
        value: "number-of-students-by-status",
        text: i18n.t("number_of_students_by_status_report"),
        permission: "studentreport_registrationnumbersbyfaculty",
    });

    // YOK Registration Status Report
    reportTypes.push({
        value: "yok-registration-status-report",
        text: i18n.t("yok_registration_status_report"),
        permission: "program_registrationstatusreport",
    });

    reportTypes.push({
        value: "exam-overlap-report",
        text: i18n.t("exam_overlap_report"),
        permission: "report_studentcollection",
    });

    // reportTypes.push({
    //     value: "graduation-eligibility-report",
    //     text: i18n.t("graduation_eligibility_report"),
    //     permission: "report_studentcollection",
    // });

    return reportTypes;
}
